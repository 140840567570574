#draft-editor__format-bar {
    position: fixed;
    top: 35px;
    left: 0px;
    height: 35px;
    /* padding: 5px 5px; */
    background: #f9f9f9;
    overflow: hidden;
    /* box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 24px; */
    z-index: 2;
}

.RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
    padding: 5px 5px;
    transition: padding 0.2s;
}

@media only screen and (min-width: 569px) {
    .RichEditor-controls {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and (min-width: 1025px) {
    .RichEditor-controls {
        padding-left: 150px;
        padding-right: 150px;
    }
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    padding: 2px 5px;
    display: inline-block;
    background: #f9f9f9;
    /* transition: background 0.2s; */
}

.RichEditor-styleButton:hover,
.RichEditor-styleButton:active {
    background: #e1e1e1;
}

.RichEditor-activeButton {
    color: orange;
    background: #eee;
}


.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
    margin: 0px;
}
