.CodeMirror {
  background: #f9f9f9 !important;
  height: auto !important;
  min-height: 100%;
  padding: 10px;
  color: rgb(32, 43, 51) !important;
  font-family: "Inter" !important;
  font-size: 13px !important;
  line-height: 1.5em;
}

html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "Inter" !important;
  background: #f9f9f9 !important;
}
